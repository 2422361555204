import { defineStore } from "pinia";

import { InvoicePaymentStatus } from "~/types/invoices";
import {
  Meta,
  ResponseType,
  Option,
  Filters,
  SyncStatus,
} from "~/types/general";
import { CustomerSingle } from "~/types/customers";
import { Bill, BillDateTypes, BillSingle, BillStatus } from "~/types/bills";
import { useTasksStore } from "~/store/tasks";
import { usePurchaseStore } from "~/store/purchase";

export const useBillsStore = defineStore("bills", () => {
  // Bills state and related functions
  const bills = ref<Bill[]>([]);
  const selectedRowsIds = ref<number[]>([]);
  const baseTableKey = ref(0);
  const meta = ref<Meta>({
    current_page: 1,
    per_page: 50,
  });
  const pageTable = ref(1);
  const perPageTable = ref(10);

  async function getBills(params?): Promise<ResponseType<Bill[]>> {
    try {
      const response = await useVaniloApi("/business/purchase-invoices", {
        params,
      });

      return response as ResponseType<Bill[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Viewing bill and customer state
  const viewingBill = ref<BillSingle>({} as BillSingle);
  const viewingBillCustomer = ref<CustomerSingle>({} as CustomerSingle);

  async function getSingleBill(id: number): Promise<ResponseType<BillSingle>> {
    try {
      const response = await useVaniloApi(`/business/purchase-invoices/${id}`);

      return response as ResponseType<BillSingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleBillForPrint(id: number) {
    try {
      const response = await useVaniloApi(`/purchase-orders/${id}/print`, {
        method: "POST",
        body: {
          format: "json",
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Date types and filters
  const dateTypes = ref<Option[]>([
    { name: "Creation Date", value: BillDateTypes.CREATION },
    { name: "Target Date", value: BillDateTypes.TARGET },
  ]);

  const filters = ref<Filters>({
    selectedDate: "",
    selectedDateRange: {
      start: "",
      end: "",
    },
    creditService: false,
    selectedConditions: [],
    pricingMethod: {
      type: "pricingMethod",
      title: "Pricing Method",
      options: usePurchaseStore().purchasingMethods,
      chosenOptions: [],
    },
  });

  // Sidebar and edit mode state
  const isOpenSidebar = ref(false);
  const editMode = ref(false);

  // Statuses and sync statuses
  const statuses = ref<Option[]>([
    { name: "Active", value: BillStatus.ACTIVE },
    { name: "Awaiting Payment", value: BillStatus.AWAITING_PAYMENT },
    { name: "Paid", value: BillStatus.PAID },
    { name: "Overdue", value: BillStatus.OVERDUE },
    { name: "Draft", value: BillStatus.DRAFT },
  ]);

  const syncStatuses = ref<Option[]>([
    { name: "Synced", value: SyncStatus.SYNCED },
    { name: "Error", value: SyncStatus.ERROR },
    { name: "Pending", value: SyncStatus.PENDING },
  ]);

  // Payment statuses and selected bills
  const paymentStatuses = ref<Option[]>([
    { name: "Unpaid", value: InvoicePaymentStatus.UNPAID },
    { name: "Paid", value: InvoicePaymentStatus.PAID },
  ]);

  const selectedBills = ref<number[]>([]);

  // Template merge tags
  const templateMergeTags = ref(["User Name", "Bill", "Due Date"]);
  const templateSMSMergeTags = ref(["User Name", "Email"]);

  // Sending bills and notifications
  async function sendBills(body: { customers: number[] }) {
    try {
      const response = await useVaniloApi(`/statements/send-statements`, {
        method: "POST",
        body,
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendBillsNotification(body) {
    try {
      const response = await useVaniloApi("/business/send-bills", {
        method: "POST",
        body,
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function newSendBillsNotification(body) {
    try {
      const response = await useVaniloApi("/business/new-send-bills", {
        method: "POST",
        body,
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Syncing bills
  async function batchSyncBills(body, storeId?: number) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(`/xero/batch-sync-bills/${storeId}`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function bulkSyncBillsByIds(body, storeId?: string) {
    useIsLoading(true);
    try {
      const response = (await useVaniloApi(`/xero/bulk-sync-bills/${storeId}`, {
        method: "POST",
        body,
      })) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Bulk actions
  async function bulkBillsSendNotification(payload?) {
    let response: any;

    const tasksStore = useTasksStore();

    try {
      response = await useVaniloApi(`/purchase-orders/bulk-notify`, {
        method: "POST",
        body: {
          ...(selectedRowsIds.value.length && {
            purchase_orders: selectedRowsIds.value,
          }),
          notification: payload,
        },
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    tasksStore.taskId = response.data.id;
    selectedRowsIds.value = [];

    return response;
  }

  async function bulkExportByIds(body) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(
        `/business/purchase-invoices/bulk-export`,
        {
          method: "POST",
          body,
        }
      );

      const blob = new Blob([response as BlobPart], {
        type: "text/plain",
      });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");

      link.href = blobUrl;
      link.download = `export-business-purchase-invoice-items-report.txt`;
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(blobUrl);

      return {
        url: blobUrl,
      };
    } catch (error) {
      console.log(error);
      useErrorNotifications(error);
    } finally {
      useIsLoading(false);
    }
  }

  return {
    bills,
    selectedRowsIds,
    baseTableKey,
    meta,
    pageTable,
    perPageTable,
    viewingBill,
    viewingBillCustomer,
    dateTypes,
    filters,
    isOpenSidebar,
    editMode,
    statuses,
    syncStatuses,
    paymentStatuses,
    selectedBills,
    templateMergeTags,
    templateSMSMergeTags,
    getBills,
    getSingleBill,
    getSingleBillForPrint,
    sendBills,
    batchSyncBills,
    bulkBillsSendNotification,
    bulkSyncBillsByIds,
    sendBillsNotification,
    bulkExportByIds,
    newSendBillsNotification,
  };
});
